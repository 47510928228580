import BudgetVisitCreate from './BudgetVisitCreate'
import BudgetVisitDelete from './BudgetVisitDelete'
import BudgetVisitUpdate from './BudgetVisitUpdate'
import {
  fetchBudgetVisits,
  toggleBudgetVisit,
  togglePercentageVisit,
} from 'actions/budgetVisitActions'
import EmptyList from 'components/common/EmptyList'
import Modal from 'components/common/Modal'
import Table from 'components/common/tables/Table'
import SectionHeaderMenu from 'components/nav/SectionHeaderMenu'
import { useAuth } from 'hooks/useAuth'
import useModal from 'hooks/useModal'
import _ from 'lodash'
import { MDBCardBody } from 'mdbreact'
import React, { useEffect, useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'

const BudgetVisitList = () => {
  // hooks
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    footer,
    setFooter,
    actions,
    setActions,
    selected,
    setSelected,
    params,
    setParams,
    setModalState,
  } = useModal()
  const auth = useAuth()
  const dispatch = useDispatch()

  // redux hooks
  const { budgetVisitsAvailable, lastAction } = useSelector(
    (state) => state.budgetVisits
  )

  // local state
  const [tableData, setTableData] = useState({
    columns: [
      {
        label: '#',
        field: 'id',
        sort: 'desc',
      },
      {
        label: 'Name',
        field: 'name',
        sort: 'desc',
        minimal: 'lg',
      },
      {
        label: 'Date Created (UTC)',
        field: 'created_at',
        date: true,
        sort: 'desc',
      },
      {
        label: 'Available',
        field: 'is_available',
        toggleControl: true,
        sort: 'disabled',
      },
      {
        label: 'Percentage',
        field: 'is_percentage',
        toggleControl: true,
        sort: 'disabled',
        onChange: (item, toggle) => {
          dispatch(togglePercentageVisit(item, toggle))
        },
      },
      {
        label: '',
        field: 'buttons',
        sort: 'disabled',
        minimal: 'lg',
      },
    ],
  })

  const toggleModal = useCallback(
    (e, type, item) => {
      if (e) e.preventDefault()

      if (type === 'new') {
        setHeaderText('New Budget Visit')
        setFooter(true)
        setActions([
          {
            text: 'Submit',
            color: 'primary',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ])
      } else if (type === 'edit') {
        setHeaderText('Edit Budget Visit')
        setFooter(true)
        setActions([
          {
            text: 'Update',
            color: 'primary',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ])
        setSelected(item)
      } else if (type === 'delete') {
        setHeaderText('Delete Budget Visit')
        setFooter(true)
        setActions([
          {
            text: 'Delete',
            color: 'danger',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ])
        setSelected(item)
      }
      if (type) {
        setModalState(true)
      } else {
        setModalState(false)
      }
    },
    [formId, setActions, setFooter, setHeaderText, setModalState, setSelected]
  )

  const handleAvailabilityChange = (item, toggle) => {
    dispatch(toggleBudgetVisit(item, toggle))
  }

  useEffect(() => {
    dispatch(fetchBudgetVisits('list'))
  }, [dispatch])

  const sectionHeaderMenu = {
    order: 2,
    show: true,
    linkItems: [
      {
        name: 'Create',
        url: `/admin/budget`,
        exact: true,
        additionalProps: {
          active: true,
          onClick: (e) => toggleModal(e, 'new'),
          className: 'btn-sm',
        },
      },
    ],
  }

  const renderModalBody = () => {
    if (headerText === 'New Budget Visit') {
      return (
        <BudgetVisitCreate
          formId={formId}
          params={params}
          setParams={setParams}
          closeModal={() => setModalState(false)}
        />
      )
    } else if (headerText === 'Edit Budget Visit') {
      return (
        <BudgetVisitUpdate
          formId={formId}
          params={params}
          setParams={setParams}
          selected={selected}
          setSelected={setSelected}
          closeModal={() => setModalState(false)}
        />
      )
    } else if (headerText === 'Delete Budget Visit') {
      return (
        <BudgetVisitDelete
          formId={formId}
          selected={selected}
          closeModal={() => setModalState(false)}
        />
      )
    }

    return ''
  }

  const renderTable = () => {
    if (_.size(budgetVisitsAvailable)) {
      return (
        <Table
          section="Budget Visit"
          tableData={tableData}
          setTableData={setTableData}
          rowData={budgetVisitsAvailable}
          sort={['id', 'desc']}
          toggleAvailability={handleAvailabilityChange}
          toggleModal={toggleModal}
          updatedState={lastAction}
          downloadableCSV
          editButton
          deleteButton
        />
      )
    } else if (_.isEmpty(budgetVisitsAvailable)) {
      return (
        <EmptyList
          name="budget vist"
          namePlural="budget visits"
          userRole={auth?.user?.user_level}
          createElement={
            <span className="link" onClick={(e) => toggleModal(e, 'new')}>
              Create
            </span>
          }
        />
      )
    }
    return ''
  }

  return (
    <>
      <SectionHeaderMenu menuData={sectionHeaderMenu} />
      <MDBCardBody>{renderTable()}</MDBCardBody>
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={footer}
        actionButtons={actions}
      >
        {renderModalBody()}
      </Modal>
    </>
  )
}

export default BudgetVisitList
