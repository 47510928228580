import React from 'react'
import MaskedInput from 'react-text-mask'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 10,
  allowNegative: false,
  allowLeadingZeroes: false,
}

const CurrencyInput = ({ ...inputProps }) => {
  let prefix = inputProps.prefix ?? defaultMaskOptions.prefix
  let suffix = inputProps.suffix ?? defaultMaskOptions.suffix

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    prefix,
    suffix,
  })

  return <MaskedInput mask={currencyMask} {...inputProps} />
}

export default CurrencyInput
