import ContractDelete from './ContractDelete'
import ContractForm from './forms/ContractForm'
import {
  fetchContracts,
  updateContract,
} from 'actions/contracts/contractActions'
import { fetchContractStates } from 'actions/contracts/contractStateActions'
import Modal from 'components/common/Modal'
import Section from 'components/common/Section'
import { useAuth } from 'hooks/useAuth.js'
import useModal from 'hooks/useModal'
import _ from 'lodash'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { findSelected, selectValueSplit } from 'utils/formUtils'

const ContractUpdate = ({
  match: {
    params: { id: contractId },
  },
}) => {
  //// HOOKS.
  const dispatch = useDispatch()
  const { user } = useAuth()
  const { is_admin } = user ?? {}
  const {
    modalOpen,
    headerText,
    setHeaderText,
    formId,
    setFooter,
    setActions,
    selected,
    setSelected,
    setModalState,
  } = useModal()

  //// GLOBAL STATE.
  const { contractStates } = useSelector((state) => state)
  const { contractsAvailable, contractErrors } = useSelector(
    (state) => state.contracts
  )

  //// LOCAL STATE.
  const [currentContract, setCurrentContract] = useState({})
  const [customProperties, setCustomProperties] = useState({})

  //// LIFECYCLE HELPERS.

  //// MEMOS.
  const handleSubmission = useMemo(
    () => buildHandleSubmission(currentContract, customProperties, dispatch),
    [currentContract, customProperties, dispatch]
  )

  //// EFFECTS.
  useEffect(() => {
    if (_.isEmpty(contractsAvailable)) {
      dispatch(fetchContracts())
    }
    if (_.isEmpty(currentContract)) {
      setCurrentContract(contractsAvailable[contractId])
    }
    if (_.size(currentContract)) {
      dispatch(fetchContractStates(currentContract))
    }
  }, [
    dispatch,
    currentContract,
    setCurrentContract,
    contractsAvailable,
    contractId,
  ])

  //// RENDER VARS & HELPERS.
  const toggleModal = (e, type, item) => {
    if (e) e.preventDefault()

    if (type === 'delete') {
      setHeaderText('Delete Contract')
      setFooter(true)
      setActions([
        {
          text: 'Delete',
          color: 'danger',
          additionalProps: {
            form: formId,
            type: 'submit',
          },
        },
      ])
      setSelected(item)
    }

    if (type) {
      setModalState(true)
    } else {
      setModalState(false)
    }
  }

  //// RENDER.
  return !currentContract || !user || _.isEmpty(currentContract) ? (
    ''
  ) : (
    <Section
      title={`Contract #${currentContract.id}`}
      description="Change selections to edit the contract."
      menu={{
        order: 2,
        show: true,
        linkItems: [
          {
            name: 'View',
            url: `/contracts/${currentContract.id}`,
            exact: true,
          },
          {
            name: 'Edit',
            url: `/contracts/${currentContract.id}/edit`,
            exact: true,
          },
          ...(!is_admin
            ? []
            : [
                {
                  name: 'Delete',
                  url: `/contracts/${currentContract.id}/remove`,
                  exact: true,
                  additionalProps: {
                    onClick: (e) => toggleModal(e, 'delete', currentContract),
                    className: 'text-danger',
                  },
                },
              ]),
        ],
      }}
      breadcrumbs
      footer={[
        {
          text: 'Update',
          color: 'primary',
          additionalProps: {
            form: 'form_contract',
            type: 'submit',
          },
        },
      ]}
    >
      <ContractForm
        contract={currentContract}
        states={contractStates}
        submit={handleSubmission}
        errors={contractErrors}
        customProperties={customProperties}
        setCustomProperties={setCustomProperties}
      />
      <Modal
        isOpen={modalOpen}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={true}
        actionButtons={[
          {
            text: 'Delete',
            color: 'danger',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ]}
      >
        <ContractDelete
          formId={formId}
          selected={selected}
          closeModal={() => setModalState(false)}
        />
      </Modal>
    </Section>
  )
}

export default ContractUpdate

const buildHandleSubmission =
  (currentContract, customProperties, dispatch) =>
  (e, params, options, states) => {
    e.preventDefault()

    const studySelected = findSelected(options.study)
    const typeSelected = findSelected(options.contractType)
    const subtypeSelected = findSelected(options.contractSubtype)
    const stateSelected = findSelected(states)
    const currencySelected = findSelected(options.currency)
    const contactSelected = findSelected(options.primaryInvestigator)
    const siteSelected = findSelected(options.primarySite)
    const updatedParams = _.mapValues(params, (prop, key) => {
      if (currentContract[key] !== prop && prop !== undefined) return prop
    })
    const contractParams = _.omitBy(updatedParams, _.isNil) // contractParams is what gets sent to the API; had to fix by switching from _.pickBy
    contractParams.id = currentContract.id

    const updatedContract = _.mergeWith(currentContract, contractParams)

    // study settings
    if (currentContract.study.id !== studySelected[0].id) {
      contractParams.study_id = studySelected[0].id
      updatedContract.study = {
        id: studySelected[0].id,
        name: studySelected[0].value,
      }
    }

    // type settings
    if (currentContract.contract_type.id !== typeSelected?.[0]?.id) {
      if (typeSelected?.[0]?.id) {
        contractParams.contract_type_id = typeSelected?.[0]?.id
        updatedContract.contract_type = {
          id: typeSelected[0].id,
          name: typeSelected[0].value,
        }
      } else {
        contractParams.contract_type_id = ''
      }
    }

    //subtype settings
    if (currentContract.contract_subtype_id !== subtypeSelected?.[0]?.id) {
      contractParams.contract_subtype_id = subtypeSelected?.[0]?.id || ''
    }
    if (subtypeSelected.length > 0) {
      updatedContract.contract_subtype = {
        id: subtypeSelected[0].id,
        name:
          subtypeSelected[0].value !== 'None' ? subtypeSelected[0].value : '',
      }
    }

    // state settings
    if (Number(stateSelected[0].value) !== currentContract.current_state) {
      contractParams.current_state = Number(stateSelected[0].value)
    }
    if (stateSelected.length > 0) {
      updatedContract.current_state = Number(stateSelected[0].value)
      updatedContract.current_state_text = stateSelected[0].text
    }

    // currency settings
    const isBudget =
      contractParams.is_budget === undefined
        ? currentContract.is_budget
        : contractParams.is_budget
    if (
      isBudget &&
      currencySelected.length &&
      currentContract.currency_id !== currencySelected[0].id
    ) {
      contractParams.currency_id = currencySelected[0].id
    }
    if (currencySelected.length > 0) {
      const splitCurrencyValue = selectValueSplit(currencySelected[0].value)
      updatedContract.currency = {
        id: currencySelected[0].id,
        name: splitCurrencyValue[0],
        code: splitCurrencyValue[1],
      }
    }

    // contact settings
    if (currentContract.primary_investigator_id !== contactSelected?.[0]?.id) {
      contractParams.primary_investigator_id = contactSelected[0].id
    }
    if (contactSelected.length > 0) {
      const contactName = contactSelected[0].value.split(', ')
      updatedContract.primary_investigator = {
        id: contactSelected[0].id,
        name:
          contactName[0] !== 'None'
            ? `${contactName[1]} ${contactName[0]}`
            : '',
      }
    }

    // site settings
    if (currentContract.primary_site_id !== siteSelected?.[0]?.id) {
      contractParams.primary_site_id = siteSelected[0].id
    }
    if (siteSelected.length > 0) {
      updatedContract.primary_site = {
        id: siteSelected[0].id,
        name: siteSelected[0].value !== 'None' ? siteSelected[0].value : '',
      }
    }

    Object.assign(updatedContract.custom_properties, customProperties)

    dispatch(updateContract(contractParams, updatedContract))
  }
