import {
  SET_BUDGET_VISITS,
  SET_BUDGET_VISIT_OPTIONS,
  UNSET_BUDGET_VISIT_OPTIONS,
  CREATE_BUDGET_VISIT,
  DELETE_BUDGET_VISIT,
  UPDATE_BUDGET_VISIT,
  TOGGLE_BUDGET_VISIT,
  UNSET_BUDGET_VISIT_ACTION,
  ERROR_BUDGET_VISIT,
  UNSET_ERROR_BUDGET_VISIT,
  TOGGLE_BUDGET_PERCENTAGE,
} from 'actions/types'
import restApi from 'apis/restApi'
import { fetchToken } from 'utils/authUtils'

const budgetEndPoint = '/budget'

// Pass data to reducers
export const setBudgetVisit = (obj, type, budgetVisit) => {
  const action = { type: type, payload: obj }
  if (budgetVisit) action.currentBudgetVisit = budgetVisit
  return action
}

// Non-API Calls
export const clearBudgetVisitOptions = () => async (dispatch) => {
  dispatch(setBudgetVisit([], UNSET_BUDGET_VISIT_OPTIONS))
}
export const clearBudgetVisitAction = () => async (dispatch) => {
  dispatch(setBudgetVisit({}, UNSET_BUDGET_VISIT_ACTION))
}
export const fetchExistingBudgetVisitOptions =
  (budgetVisit) => async (dispatch) => {
    dispatch(setBudgetVisit([], SET_BUDGET_VISIT_OPTIONS, budgetVisit))
  }
export const clearBudgetVisitErrors = () => async (dispatch) => {
  dispatch(setBudgetVisit({}, UNSET_ERROR_BUDGET_VISIT))
}

// API Calls
export const fetchBudgetVisits = (results, budgetVisit) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.get(budgetEndPoint, {
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })

    if (results === 'list') {
      dispatch(setBudgetVisit(res.data, SET_BUDGET_VISITS))
    } else if (results === 'form') {
      dispatch(setBudgetVisit(res.data, SET_BUDGET_VISIT_OPTIONS, budgetVisit))
    }
  }
}

export const createBudgetVisit = (params) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.post(budgetEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setBudgetVisit(res.data, CREATE_BUDGET_VISIT))
    } catch (err) {
      const error = { ...err.response.data, type: 'create' }
      dispatch(setBudgetVisit(error, ERROR_BUDGET_VISIT))
    }
  }
}

export const deleteBudgetVisit = (budget) => async (dispatch) => {
  if (fetchToken()) {
    const res = await restApi.delete(budgetEndPoint, {
      data: { id: budget.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(setBudgetVisit(res.data, DELETE_BUDGET_VISIT, budget))
  }
}

export const updateBudgetVisit = (params, budget) => async (dispatch) => {
  if (fetchToken()) {
    try {
      const res = await restApi.patch(budgetEndPoint, params, {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      })
      dispatch(setBudgetVisit(res.data, UPDATE_BUDGET_VISIT, budget))
    } catch (err) {
      const error = { ...err.response.data, type: 'edit' }
      dispatch(setBudgetVisit(error, ERROR_BUDGET_VISIT))
    }
  }
}

export const toggleBudgetVisit = (budget, toggle) => async (dispatch) => {
  const toggleEndPoint = `${budgetEndPoint}/${toggle ? 'enable' : 'disable'}`
  if (fetchToken()) {
    const res = await restApi.delete(toggleEndPoint, {
      data: { id: budget.id },
      headers: { Authorization: `Bearer ${fetchToken()}` },
    })
    dispatch(
      setBudgetVisit({ res, toggle: toggle }, TOGGLE_BUDGET_VISIT, budget)
    )
  }
}

export const togglePercentageVisit = (budget, toggle) => async (dispatch) => {
  const toggleEndPoint = `${budgetEndPoint}/${
    toggle ? 'enablePercentage' : 'disablePercentage'
  }`
  if (fetchToken()) {
    const res = await restApi.patch(
      toggleEndPoint,
      { id: budget.id },
      {
        headers: { Authorization: `Bearer ${fetchToken()}` },
      }
    )
    dispatch(
      setBudgetVisit({ res, toggle: toggle }, TOGGLE_BUDGET_PERCENTAGE, budget)
    )
  }
}
