import history from '../../../history'
import TableButton from './TableButton'
import Select from 'components/common/forms/Select'
import { useLocalStorage } from 'hooks/useLocalStorage'
import _ from 'lodash'
import { MDBCol, MDBDataTableV5, MDBSwitch, MDBRow } from 'mdbreact'
import moment from 'moment'
import React, { useEffect, useState, useCallback, useRef } from 'react'
import { formatSnakeCase } from 'utils/commonUtils'
import { formatNumber } from 'utils/formatNumbersUtil'

const customPropertiesToColumns = (items) => {
  if (items) {
    const customPropertyOptions = items.map((customItem) => {
      return {
        label: customItem.property.name,
        field: customItem.property.id.toString(),
        path: `custom_properties['${customItem.property.name}']`,
        isCustom: true,
      }
    })
    const filterDuplicateOptions = customPropertyOptions.reduce(
      (acc, current) => {
        const itemFound = acc.find((item) => item.field === current.field)
        if (!itemFound) {
          return acc.concat([current])
        } else {
          return acc
        }
      },
      []
    )
    return filterDuplicateOptions
  }
  return []
}

const columnsToMultiSelectOptions = (items, customItems) => {
  const selectOptions = []
  if (items) {
    const itemOptions = items.map((item) => {
      return {
        text: item.label,
        value: item.field,
        checked: item.defaultActive || false,
      }
    })
    selectOptions.push(...itemOptions)
  }
  if (customItems) {
    const customPropertyOptions = customItems.map((customItem) => {
      return {
        text: customItem.property.name,
        value: customItem.property.id.toString(),
        checked: false,
      }
    })
    const filterDuplicateOptions = customPropertyOptions.reduce(
      (acc, current) => {
        const itemFound = acc.find((item) => item.value === current.value)
        if (!itemFound) {
          return acc.concat([current])
        } else {
          return acc
        }
      },
      []
    )
    selectOptions.push(...filterDuplicateOptions)
  }
  return selectOptions
}

const Table = React.memo((props) => {
  const {
    section,
    listTitle,
    tableData,
    setTableData,
    rowData,
    sort,
    pageLengthOpts,
    fetchTableLink,
    toggleAvailability,
    toggleModal,
    downloadFile,
    updatedState,
    downloadableCSV,
    infoLabel,
    clickableRow,
    linkToPage,
    showButton,
    downloadButton,
    showHistoryButton,
    editableListButton,
    editButton,
    deleteButton,
    defaultColumns,
    defaultButtons,
    customProperties,
    willSaveColumns,
    columnsAvailable,
    onSubmitColumns,
  } = props
  const [currentTableSort, setCurrentTableSort] = useState({
    column: sort ? sort[0] : 'id',
    direction: sort ? sort[1] : 'desc',
  })
  const stringColumns = tableData.columns
    ? JSON.stringify(tableData.columns)
    : JSON.stringify([])
  const stringSort = JSON.stringify(currentTableSort)

  const [columnOptions, setColumnOptions] = useState([])
  const [customPropertiesColumns, setCustomPropertiesColumns] = useState([])
  const [columnLocalStorage, setColumnLocalStorage] = useLocalStorage(
    `columns${section}`,
    []
  )

  // callback functions to limit repeated calls
  const handleRowClick = useCallback(
    (e, item) => {
      // If the table alrady has a show button and we can get the the show link
      if (showButton && fetchTableLink) {
        const isIcon = e.target.tagName === 'I'
        const isFa = e.target.classList.contains('fa')
        const isBtn =
          e.target.classList.contains('btn') || e.target.tagName === 'BUTTON'
        const isLever = e.target.classList.contains('lever')
        const isLabel = e.target.tagName === 'LABEL'
        const isInput = e.target.tagName === 'INPUT'

        if (isIcon || isFa || isBtn || isLever || isLabel || isInput)
          return false

        history.push(fetchTableLink('show', item))
      }
    },
    [fetchTableLink, showButton]
  )

  const handleModalChange = useCallback(toggleModal, [])
  const handleSwitchChange = useCallback(toggleAvailability, [])

  const buildRowData = useCallback(
    (columns) => {
      const columnData =
        columns?.length > 0
          ? JSON.parse(JSON.stringify(columns))
          : JSON.parse(stringColumns)
      const { column, direction } = JSON.parse(stringSort)
      const newRows = _.map(rowData, (rowItem) => {
        const row = {}
        _.each(columnData, (column) => {
          if (column.isListOnly && !rowItem.property?.property_type?.is_list) {
            return null
          }

          if (column.date) {
            const dateField = _.get(rowItem, column.field)
            row[column.field] = dateField
              ? moment.utc(dateField).format('YYYY/MM/DD HH:mm')
              : ''
          } else if (column.toggleControl) {
            row[column.field] = (
              <MDBSwitch
                key={`switch-${rowItem.id}`}
                className="availability-switch"
                checked={rowItem[column.field]}
                getValue={(toggle) => {
                  let tempFn = handleSwitchChange
                  const tempColData = tableData.columns.find(
                    (c) => c.field === column.field
                  )

                  if (tempColData?.onChange) {
                    tempFn = tempColData.onChange
                  }

                  return tempFn(rowItem, toggle)
                }}
              />
            )
          } else if (column.snakeCase) {
            row[column.field] = formatSnakeCase(_.get(rowItem, column.field))
          } else if (column.field === 'buttons') {
            row[column.field] = (
              <>
                {/* vertical spacer used to stabilize row height  */}
                <div className="btn btn-flat px-0 mx-0 mb-0 mt-1" />

                {showButton ? (
                  <TableButton
                    type="show"
                    item={rowItem}
                    color="primary"
                    icon="search-plus"
                    toggleModal={handleModalChange}
                    link={
                      fetchTableLink ? fetchTableLink('show', rowItem) : false
                    }
                    label={`View ${section}`}
                  />
                ) : null}
                {downloadButton &&
                (rowItem.contract_document_id || rowItem.filename) ? (
                  <TableButton
                    type="download"
                    item={rowItem}
                    color="gray"
                    icon="cloud-download-alt"
                    download={downloadFile}
                    label={`Download ${section}`}
                  />
                ) : null}
                {editableListButton &&
                (rowItem.property_type?.code === 'lists' ||
                  rowItem.user_level === 'guest') ? (
                  <TableButton
                    type="list"
                    item={rowItem}
                    color="primary"
                    icon="list"
                    link={
                      fetchTableLink ? fetchTableLink('list', rowItem) : false
                    }
                    label={listTitle ?? `Edit ${section} List`}
                  />
                ) : null}
                {showHistoryButton ? (
                  <TableButton
                    type="history"
                    item={rowItem}
                    color="primary"
                    icon="history"
                    link={
                      fetchTableLink
                        ? fetchTableLink('history', rowItem)
                        : false
                    }
                    label={`${section} History`}
                  />
                ) : null}
                {editButton ? (
                  <TableButton
                    type="edit"
                    item={rowItem}
                    color="secondary"
                    icon="edit"
                    modal={linkToPage ? false : true}
                    toggleModal={handleModalChange}
                    link={
                      fetchTableLink ? fetchTableLink('edit', rowItem) : false
                    }
                    label={`Edit ${section}`}
                  />
                ) : null}
                {deleteButton ? (
                  <TableButton
                    type="delete"
                    item={rowItem}
                    color="red"
                    icon="trash-alt"
                    modal
                    toggleModal={handleModalChange}
                    label={`Delete ${section}`}
                  />
                ) : null}
              </>
            )
          } else if (column.isCustom) {
            const field = _.get(rowItem, column.path)
            const fieldResult = () => {
              if (field?.value) {
                return field.value.toString()
              }
              return null
            }
            row[column.field] = fieldResult()
          } else {
            const field = _.get(rowItem, column.field)
            const append = column.appendField
              ? `, ${_.get(rowItem, column.appendField)}`
              : ''
            const fieldResult = () => {
              if (column.field === 'id') {
                return Number(field)
              } else if (column.formatter && column.formatter === 'formatUSD') {
                return formatNumber(field, { prepend: '$' })
              } else if (field && append) {
                return `${field}${append}`
              } else if (field || field === false) {
                return field.toString()
              }
              return null
            }
            row[column.field] = fieldResult()
          }
        })
        if (clickableRow) row.clickEvent = (e) => handleRowClick(e, rowItem)
        return row
      })
      return _.orderBy(newRows, [column], [direction])
    },
    [
      section,
      listTitle,
      stringSort,
      stringColumns,
      showButton,
      editButton,
      deleteButton,
      downloadButton,
      showHistoryButton,
      editableListButton,
      linkToPage,
      clickableRow,
      fetchTableLink,
      downloadFile,
      handleModalChange,
      handleSwitchChange,
      handleRowClick,
      rowData,
      JSON.stringify(rowData),
    ]
  )

  const pushToTableData = useCallback(setTableData, [])
  const columnLocalStorageRef = useRef(columnLocalStorage)

  useEffect(() => {
    if (columnsAvailable?.length > 0) {
      const filterColumns = [
        ...defaultColumns,
        ...customPropertiesColumns,
      ].filter((column) => columnsAvailable.includes(column.field))
      pushToTableData((prev) => ({
        ...prev,
        columns: [...filterColumns, ...defaultButtons],
        rows: buildRowData([...filterColumns, ...defaultButtons]),
      }))
    } else if (columnLocalStorageRef?.current?.length > 0) {
      const filterColumns = [
        ...defaultColumns,
        ...customPropertiesColumns,
      ].filter((column) => columnLocalStorageRef.current.includes(column.field))
      pushToTableData((prev) => ({
        ...prev,
        columns: [...filterColumns, ...defaultButtons],
        rows: buildRowData([...filterColumns, ...defaultButtons]),
      }))
    } else if (updatedState) {
      pushToTableData((prev) => ({
        ...prev,
        rows: buildRowData(),
      }))
    }
  }, [
    updatedState,
    pushToTableData,
    buildRowData,
    columnsAvailable,
    customPropertiesColumns,
    defaultColumns,
    defaultButtons,
    willSaveColumns,
  ])

  const buildColumnSelectionDropdown = useCallback(
    (storedColumns) => {
      const availableOptions = columnsToMultiSelectOptions(
        defaultColumns,
        customProperties
      )

      const columnOptionsFromStorage = availableOptions.map((option) => {
        if (storedColumns.includes(option.value)) {
          option.checked = true
        } else {
          option.checked = false
        }
        return option
      })
      return columnOptionsFromStorage
    },
    [customProperties, defaultColumns]
  )

  const fetchColumns = useCallback(() => {
    if (columnsAvailable?.length > 0) {
      setColumnOptions(buildColumnSelectionDropdown(columnsAvailable))
      setCustomPropertiesColumns(customPropertiesToColumns(customProperties))
    } else if (columnLocalStorageRef?.current?.length > 0) {
      setColumnOptions(
        buildColumnSelectionDropdown(columnLocalStorageRef.current)
      )
      setCustomPropertiesColumns(customPropertiesToColumns(customProperties))
    } else {
      setColumnOptions(
        columnsToMultiSelectOptions(defaultColumns, customProperties)
      )
      setCustomPropertiesColumns(customPropertiesToColumns(customProperties))
    }
  }, [
    buildColumnSelectionDropdown,
    columnsAvailable,
    customProperties,
    defaultColumns,
  ])

  // if local storage is empty and default columns are set, set local storage and reload
  useEffect(() => {
    if (
      !columnLocalStorage?.length &&
      defaultColumns?.every &&
      defaultColumns.length > 0 &&
      defaultColumns.every((col) => col.field)
    ) {
      setColumnLocalStorage(defaultColumns.map((column) => column.field))
      window.location.reload()
    }
  }, [defaultColumns, columnLocalStorage, setColumnLocalStorage])

  useEffect(() => {
    fetchColumns()
  }, [fetchColumns])

  // Additional table interaction functions
  const handleTableSort = (value) => {
    setCurrentTableSort(value)
  }

  if (
    !tableData.rows ||
    !tableData.columns ||
    tableData.columns.some((x) => !x.field)
  ) {
    return ''
  }

  const handleSelectValue = (e) => {
    const filterColumns = [
      ...defaultColumns,
      ...customPropertiesColumns,
    ].filter((column) => e.includes(column.field))
    setColumnLocalStorage(e)

    if (willSaveColumns && e.length > 0 && !_.isEqual(e, columnsAvailable)) {
      onSubmitColumns(e)
    }

    pushToTableData((prev) => ({
      ...prev,
      columns: [...filterColumns, ...defaultButtons],
      rows: buildRowData([...filterColumns, ...defaultButtons]),
    }))
  }

  return (
    <>
      <MDBDataTableV5
        btn
        hover
        searchTop
        searchBottom={false}
        exportToCSV={downloadableCSV}
        noBottomColumns
        data={tableData}
        onSort={handleTableSort}
        order={[currentTableSort.column, currentTableSort.direction]}
        entriesOptions={pageLengthOpts ? pageLengthOpts : [10, 25, 50]}
        className={`table-collapsed${clickableRow ? ' table-row-links' : ''}`}
        infoLabel={infoLabel}
      />
      {columnOptions?.length > 0 && willSaveColumns && (
        <MDBRow>
          <MDBCol size="8">
            <Select
              id="multi__select__columns"
              options={columnOptions}
              getValue={handleSelectValue}
              label="Hide/Show Columns"
              defaultOption="Select Columns"
              multiple
              search
            />
          </MDBCol>
        </MDBRow>
      )}
    </>
  )
})

export default Table
