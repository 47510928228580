import ContractBudgetHistory from './ContractBudgetHistory'
import ContractBudgetUpdate from './ContractBudgetUpdate'
import { fetchContractBudgets } from 'actions/contracts/contractBudgetActions'
import ButtonIcon from 'components/common/ButtonIcon'
import Modal from 'components/common/Modal'
import Section from 'components/common/Section'
import BadgeLabel from 'components/common/badges/BadgeLabel'
import { useAuth } from 'hooks/useAuth'
import useModal from 'hooks/useModal'
import _ from 'lodash'
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBRow,
  MDBCol,
} from 'mdbreact'
import React, { useEffect, useCallback, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { useSelector, useDispatch } from 'react-redux'
import { isUserNotReadOnlyForContracts } from 'utils/authUtils'

const ContractBudgetList = ({ contract }) => {
  //// HOOKS.
  const dispatch = useDispatch()
  const { user } = useAuth() ?? {}
  const { user_level } = user ?? {}
  const {
    headerText,
    setHeaderText,
    formId,
    footer,
    setFooter,
    actions,
    setActions,
    selected,
    setSelected,
    params,
    setParams,
  } = useModal()

  //// GLOBAL STATE.
  const { contractBudgetsAvailable } = useSelector(
    (state) => state.contractBudgets
  )

  //// LOCAL STATE.
  const [updateModalState, setUpdateModalState] = useState(false)
  const [historyModalState, setHistoryModalState] = useState(false)

  //// LIFECYCLE HELPERS.

  //// MEMOS.

  //// EFFECTS.
  useEffect(() => {
    dispatch(fetchContractBudgets(contract.id))
  }, [dispatch, contract.id])

  //// RENDER VARS & HELPERS.
  const toggleModal = useCallback(
    (e, type, item) => {
      if (e) e.preventDefault()

      if (type === 'edit') {
        setHeaderText(`Edit Contract Budget: ${item.budget_visit.name}`)
        setFooter(true)
        setActions([
          {
            text: 'Update',
            color: 'primary',
            additionalProps: {
              form: formId,
              type: 'submit',
            },
          },
        ])
        setParams({
          study_budget_visit_id: item.study_budget_visit_id,
          amount: item.amount,
          currency: item.currency,
        })
        setSelected(item)
      }

      if (type === 'history') {
        setHeaderText(`History: ${item.budget_visit.name}`)
        setSelected(item)
        setFooter(false)
        setParams({
          currency: item.currency,
        })
      }

      if (type === 'edit') {
        setUpdateModalState(true)
      } else if (type === 'history') {
        setHistoryModalState(true)
      } else {
        setUpdateModalState(false)
        setHistoryModalState(false)
      }
    },
    [
      formId,
      setActions,
      setFooter,
      setHeaderText,
      setUpdateModalState,
      setSelected,
      setParams,
    ]
  )

  //// RENDER.
  return _.isEmpty(contractBudgetsAvailable) ? (
    ''
  ) : (
    <>
      <Section
        header="Contract Budget"
        description={
          contract.current_state !== 0
            ? `The budget cannot be changed when the contract is ${contract.current_state_text.toLowerCase()}`
            : 'The amount to be charged for each visit of the study'
        }
      >
        <MDBRow>
          {_.map(contractBudgetsAvailable, (budget) => {
            const prefix = budget.currency === '%' ? '' : `${budget.currency} `
            const suffix = budget.currency === '%' ? ` ${budget.currency}` : ''

            return (
              <MDBCol
                xl="3"
                lg="4"
                md="6"
                key={`${budget.study_budget_visit_id}-${
                  budget.budget_visit.id
                }-${budget.contract_budget_id || 0}`}
                className="mb-4"
              >
                <MDBCard
                  className={`contract-budget-card h-100${
                    budget.amount === 0 ? ' no-amount' : ''
                  }`}
                >
                  <MDBCardBody cascade>
                    <MDBCardTitle
                      tag="h6"
                      sub
                      className="d-flex align-items-top justify-content-between"
                    >
                      {budget.budget_visit.name}
                      <div>
                        {budget.contract_budget_id && (
                          <ButtonIcon
                            id={`${budget.budget_visit.id}-${budget.id}`}
                            label={`History for ${budget.budget_visit.name}`}
                            icon="history"
                            card
                            color="secondary"
                            onClick={(e) => toggleModal(e, 'history', budget)}
                          />
                        )}
                        {contract.current_state === 0 &&
                        isUserNotReadOnlyForContracts(user_level) ? (
                          <ButtonIcon
                            id={`${budget.budget_visit.id}-${budget.id}`}
                            label={`Edit ${budget.budget_visit.name}`}
                            icon="edit"
                            card
                            color="secondary"
                            onClick={(e) => toggleModal(e, 'edit', budget)}
                          />
                        ) : (
                          ''
                        )}
                      </div>
                    </MDBCardTitle>
                    <hr className="mt-2 mb-2" />
                    <MDBCardText
                      className={budget.amount === 0 ? ' danger-text' : ''}
                    >
                      <CurrencyFormat
                        value={budget.amount}
                        displayType={'text'}
                        thousandSeparator={true}
                        prefix={prefix}
                        suffix={suffix}
                        decimalScale={2}
                        fixedDecimalScale={true}
                      />
                      {budget.amount === 0 ? (
                        <BadgeLabel>
                          <span className="ml-1">(No amount set)</span>
                        </BadgeLabel>
                      ) : (
                        ''
                      )}
                    </MDBCardText>
                  </MDBCardBody>
                </MDBCard>
              </MDBCol>
            )
          })}
        </MDBRow>
      </Section>
      <Modal
        isOpen={updateModalState}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={footer}
        actionButtons={actions}
      >
        <ContractBudgetUpdate
          contract={contract}
          formId={formId}
          params={params}
          setParams={setParams}
          selected={selected}
          setSelected={setSelected}
          closeModal={() => setUpdateModalState(false)}
        />
      </Modal>
      <Modal
        isOpen={historyModalState}
        toggle={toggleModal}
        headerText={headerText}
        includeFooter={footer}
        actionButtons={actions}
      >
        <ContractBudgetHistory
          selected={selected}
          contractId={contract.id}
          currency={params.currency}
        />
      </Modal>
    </>
  )
}

export default ContractBudgetList
